import { ethers } from "ethers";
import { web3provider } from "src/plugins/Web3Connection";
import { writeContract } from "@wagmi/core";
import { toEtherValue, toWeiValue } from "src/helpers/numeric.helper";
import { tokenAddress } from "src/helpers/contracts.helper";

const compiledNFTContract = require("src/contracts/DropERC721.json");
//const compiledVestingContract = require("src/contracts/VestingERC721.json");
var bigInt = require("big-integer");

const executeContract = (nftType, methodName, payableValue = 0, ...params) =>
	new Promise(async (resolve, reject) => {
		const contractAddress = tokenAddress(nftType);
		const compiledContract = compiledNFTContract; //contract === "nft" ? compiledNFTContract : compiledVestingContract;

		callOnContract(contractAddress, compiledContract.abi, methodName, payableValue, params)
			.then(response => {
				resolve(response);
			})
			.catch(error => reject(error));
	});

const callOnContract = (contractAddress, contractABI, methodName, payableValue, params) =>
	new Promise(async (resolve, reject) => {
		if (!web3provider) {
			reject("Non-Ethereum browser detected. You should consider trying MetaMask!");
		}

		try {
			const signer = web3provider.getSigner();

			console.log(contractAddress)

			const transactionContract = new ethers.Contract(
				contractAddress,
				contractABI,
				payableValue ? signer : web3provider
			);

			const name = await transactionContract["name"]();
			console.log(name);

			const tx = await transactionContract[methodName](
				...params,
				payableValue ? { value: payableValue.toString() } : {}
			);
			if (payableValue) {
				const txRcpt = await tx.wait();
				if (txRcpt.status === 1) resolve(txRcpt);
				else reject("error");
			} else {
				resolve(tx);
			}
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});

export const getActiveClaimCondition = nftType =>
	new Promise(async (resolve, reject) => {
		let response = { pricePerToken: 0 };
		try {
			let activeClaimID = await executeContract(nftType, "getActiveClaimConditionId");
			if (typeof activeClaimID === "bigint")
				activeClaimID = Number(activeClaimID)

			if (activeClaimID >= 0) {
				// get the claim details
				const claimDetails = await executeContract(
					nftType,
					"getClaimConditionById",
					0,
					activeClaimID
				);
				console.log(claimDetails)

				if (claimDetails) {
					response = {
						conditionId: activeClaimID,
						startTime: Number(claimDetails[0]),
						maxClaimableSupply: Number(claimDetails[1]),
						supplyClaimed: Number(claimDetails[2]),
						quantityLimitPerWallet: Number(claimDetails[3]),
						merkleRoot:
							claimDetails[4] !== "0x0000000000000000000000000000000000000000000000000000000000000000"
								? claimDetails[4]
								: null,
						pricePerToken: toEtherValue(claimDetails[5]),
						currency: claimDetails[6],
					};
				}
			}
		} catch (e) {
			console.log(e);
		}

		resolve(response);
	});

export const getWalletsClaimBalance = (nftType, account) =>
	new Promise(async resolve => {
		let response = {};

		try {
			const allocationDetails = await executeContract(nftType, "walletAllocationCount", 0, account);
			const claimedDetails = await executeContract(nftType, "walletClaimCount", 0, account);

			if (allocationDetails && Number(allocationDetails) > 0) {
				response.allocatedQty = Number(allocationDetails);
			}

			if (claimedDetails && Number(claimedDetails) > 0) {
				response.claimedQty = Number(claimedDetails);
			}
		} catch (e) {
			console.log(e);
		}

		resolve(response);
	});

export const verifyClaimMerkleProof = (nftType, conditionId, claimer, proofs) =>
	new Promise(async (resolve, reject) => {
		try {
			console.log(conditionId, claimer, proofs);
			await executeContract(nftType, "verifyClaimMerkleProof", 0, conditionId, claimer, proofs);
			resolve(true);
		} catch (e) {
			reject(e);
		}
	});

export const claimNFT = (nftType, account, provider, quantity, currency, price, proofs) =>
	new Promise(async (resolve, reject) => {
		try {
			console.log(bigInt(toWeiValue(`${price * quantity}`)).toString());
			if (provider === "walletconnect") {
				await writeContract({
					address: tokenAddress(nftType),
					abi: compiledNFTContract.abi,
					functionName: "claim",
					args: [account, quantity, currency, bigInt(toWeiValue(`${price}`)).toString(), proofs],
					chainId: parseInt(process.env.REACT_APP_CHAIN_ID),
					value: bigInt(toWeiValue(`${price * quantity}`)).toString(),
				})
			} else {
				const tx = await executeContract(
					nftType,
					"claim",
					toWeiValue(`${price * quantity}`),
					account,
					quantity,
					currency,
					toWeiValue(`${price}`),
					proofs
				);
			}
			resolve(true);
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});

export const transferToken = (account, sendToWallet, tokenID) =>
	new Promise(async (resolve, reject) => {
		try {
			const compiledContract = compiledNFTContract;

			const signer = web3provider.getSigner();

			const transactionContract = new ethers.Contract(tokenAddress(''), compiledContract.abi, signer);

			const tx = await transactionContract["safeTransferFrom(address,address,uint256)"](
				account,
				sendToWallet,
				tokenID
			);
			const txRcpt = await tx.wait();
			console.log("m here");
			resolve(txRcpt);
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});

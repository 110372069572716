import NFTStack from "src/componets/NFTStack";
import { Button } from "react-bootstrap";
import BuyButton from "../BuyButton";

const CollectiveBlock = () => {
	return (
		<>
			<div id="collective-block">
				<div className="container-fluid gx-0">
					<div className="row gx-0 align-items-center">
						<div className="col-12 col-md-5 float-end">
							<div className="brand-video">
								<video controls autoPlay muted loop playsInline width="100%">
									<source src="images/porkie.mp4" type="video/mp4" />
								</video>
							</div>
						</div>
						<div className="col-12 col-md-7">
							<div className="content">
								<div className="mobile-background-block">
									<h1>
										<span className="block">
											<span className="thin">Porkie Pig Farm NFT</span>
										</span>
										<span className="block">Foodstarter + Alayon Piggery</span>{" "}
									</h1>
									<h3>Companies: Foodstarter PH + Alayon Piggery Bacolod</h3>
								</div>
								<div className="mobile-regular-block">
									<h3 className="highlighted" style={{ marginBottom: "25px" }}>
										Price 1 ETH
									</h3>
									<h3>Perks (Worth 2 ETH)</h3>
									<p>
										20% Sales Proceeds Share x 3yrs of the NFT Value
										<br />
										<small>
											(20% revenue share for the value of ETH at the point of purchase strictly)
										</small>
									</p>
									<p>Initial purchase value can be renewed after 3 yrs for the next farming cycle or return back to collector upon request via email</p>
									<p>Future food tasting event with Foodstarter partner restaurants.</p>
									<p>Discount for Franchise of Tonkatsu Café in the Philippine</p>
									<BuyButton type="porkie" cost="1 ETH" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="adlai-block">
				<div className="container-fluid gx-0">
					<div className="row gx-0 align-items-center">
						<div className="col-12 col-md-5">
							<div className="brand-video">
								<video controls autoPlay muted loop playsInline width="100%">
									<source src="images/adlai.mp4" type="video/mp4" />
								</video>
							</div>
						</div>
						<div className="col-12 col-md-7">
							<div className="content">
								<div className="mobile-background-block">
									<h1>
										<span className="block">
											<span className="thin">Adlai Farm NFT</span>
										</span>
										<span className="block">Foodstarter + Ohcrop!</span>{" "}
									</h1>
									<h3>Companies: Foodstarter PH + OhCrop Philippines</h3>
								</div>
								<div className="mobile-regular-block">
									<h3 className="highlighted" style={{ marginBottom: "25px" }}>
										Price 1 ETH
									</h3>
									<h3>Perks (Worth 2 ETH)</h3>
									<p>
										20% Sales Proceeds Share x 3yrs of the NFT Value
										<br />
										<small>
											(20% revenue share for the value of ETH at the point of purchase strictly)
										</small>
									</p>
									<p>Initial purchase value can be renewed after 3 yrs for the next farming cycle or return back to collector upon request via email</p>
									<p>1kg of Adlai Rice give away</p>
									<p>Future Food Tasting event with OhCrop! partner restaurants</p>
									<BuyButton type="adlai" cost="1 ETH" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="foodtruck-block">
				<div className="container-fluid gx-0">
					<div className="row gx-0 align-items-center">
						<div className="col-12 col-md-5">
							<div className="brand-video">
								<video controls autoPlay muted loop playsInline width="100%">
									<source src="images/foodtruck.mp4" type="video/mp4" />
								</video>
							</div>
						</div>
						<div className="col-12 col-md-7">
							<div className="content">
								<div className="mobile-background-block">
									<h1>
										<span className="block">
											<span className="thin">Designer Foodstarter Foodtruck NFTs</span>
										</span>
									</h1>
									<h3>Companies: Foodstarter PH + Automate Philippines</h3>
								</div>
								<div className="mobile-regular-block">
									<h3 className="highlighted" style={{ marginBottom: "25px" }}>
										Price 1 ETH
									</h3>
									<p><strong>1 Food Truck = 5 ETH</strong></p>
									<h3>Perks</h3>
									<p>Discount at partner foodtrucks in Philippines</p>
									<p>
										Sales proceeds share @ 20% per year from date of NFT purchase.
										<br />
										<small>
											(20% revenue share for the value of ETH at the point of purchase strictly)
										</small>
									</p>
									<p>Initial Purchase Quantum to be called back after each stage of payouts to be done after every 3mths via smart contract to assigned wallet.</p>
									<BuyButton type="foodtruck" cost="1 ETH" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid media-logos-container">
				<div className="media-logos">
					<div className="row gx-0 align-items-center">
						<div className="col-12 text-center">
							<h2>GUIDES FOR NFT MARKETPLACE</h2>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CollectiveBlock;

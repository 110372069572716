import { Button } from "react-bootstrap";
import { useContext } from "react";
import { DrawerContext } from "src/providers/DrawerProvider";

export default ({ type, cost }) => {
	const { openDrawer } = useContext(DrawerContext);

	const buyNow = e => {
		e.preventDefault();
		openDrawer(type);
	};

	return (
		<p className="mt-5">
			<Button variant="primary" size="lg" onClick={buyNow}>
				Buy Now for {cost}
			</Button>
		</p>
	);
};

import React, { useEffect, useState, createContext } from "react";
//import { useAccount, useDisconnect } from "@web3modal/react";
import { useAccount, useContract, useDisconnect } from "wagmi";
import { detectNetworkAccount } from "src/plugins/Web3Connection";

const BlockchainDefaultData = {
	account: null,
	networkId: process.env.REACT_APP_CHAIN_ID,
	connectedVia: "matamask",
	connectToBlockchain: () => {},
	disconnectFromBlockchain: () => {},
};

export const BlockchainContext = createContext(BlockchainDefaultData);

const BlockchainProvider = props => {
	const [tryReconnect, setTryReconnect] = useState(0);
	const [blockchainInfo, setBlockchainInfo] = useState(BlockchainDefaultData);
	const [processing, setProcessing] = useState(false);

	const accountConnectedWithWalletConnect = useAccount();
	const { disconnect: disconnectWalletConnect } = useDisconnect();

	const updateBlockchainInfo = () => {
		setProcessing(true);

		try {
			detectNetworkAccount()
				.then(info => {
					setBlockchainInfo({ ...info, connectedVia: "metamask" });
				})
				.catch(err => {
					console.log(err);
					// user disconnected the account
					setBlockchainInfo(BlockchainDefaultData);
				})
				.finally(() => {
					setProcessing(false);
				});
		} catch (e) {
			setProcessing(false);
			console.log(e);
		}
	};

	useEffect(() => {
		if (tryReconnect === 0) return;

		if (window.ethereum) {
			window.ethereum.on("accountsChanged", function (accounts) {
				updateBlockchainInfo();
			});

			window.ethereum.on("chainChanged", function (network) {
				updateBlockchainInfo();
			});
		}

		updateBlockchainInfo();

		setTryReconnect(0);
	}, [tryReconnect]);

	useEffect(() => {
		if (
			accountConnectedWithWalletConnect.isConnected &&
			accountConnectedWithWalletConnect.connector &&
			accountConnectedWithWalletConnect.address !== blockchainInfo.account
		) {
			accountConnectedWithWalletConnect.connector.getChainId().then(id => {
				setBlockchainInfo({
					account: accountConnectedWithWalletConnect.address,
					networkId: parseInt(process.env.REACT_APP_CHAIN_ID),
					connectedVia: "walletconnect",
				});
			});
		}
	}, [accountConnectedWithWalletConnect]);

	const connectToBlockchain = () => {
		setTryReconnect(1);
	};

	const disconnectFromBlockchain = () => {
		// if (blockchainInfo.connectedVia === "walletconnect") disconnectWalletConnect();
		setBlockchainInfo(BlockchainDefaultData);
	};

	const connectedWithEmail = ({ walletAddress }) => {
		setBlockchainInfo({
			account: walletAddress,
			networkId: parseInt(process.env.REACT_APP_CHAIN_ID),
			connectedVia: "paper",
		});
	};

	return (
		<BlockchainContext.Provider
			value={{ ...blockchainInfo, processing, connectToBlockchain, disconnectFromBlockchain, connectedWithEmail }}
		>
			{props.children}
		</BlockchainContext.Provider>
	);
};

export default BlockchainProvider;

import Frontend from "./layouts/Frontend";
import Home from "./pages/Home";

const routes = [
	{
		path: "/",
		element: <Frontend />,
        children: [
            {
                path: "/",
                element: <Home />
            }
        ]
	},
];

export default routes;

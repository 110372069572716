import React, { useState, createContext } from "react";

export const DrawerDefaultData = {
	open: false,
};

export const DrawerContext = createContext(DrawerDefaultData);

const DrawerProvider = props => {
	const [open, setOpen] = useState(false);
	const [drawerFor, setDrawerFor] = useState(null);

	const openDrawer = selectedType => {
		//if (selectedType !== "goldNFT") return;

		setOpen(true);

		setDrawerFor(selectedType);
	};

	const closeDrawer = () => {
		setOpen(false);
	};

	return (
		<DrawerContext.Provider value={{ open, drawerFor, openDrawer, closeDrawer }}>
			{props.children}
		</DrawerContext.Provider>
	);
};

export default DrawerProvider;

export default () => (
	<div id="partners-block">
		<div>
			<div className="container">
				<div className="row gx-4 g-md-5 mt-5">
					<div className="col-12 col-md-4 text-center">
						<img src="https://marketplace.foodstarter.io/assets/images/no-history.png" alt="step 1" />
						<h5>Step 1</h5>
						<p>Connect your web3 blockchain wallet</p>
					</div>
					<div className="col-12 col-md-4 text-center">
						<img src="https://marketplace.foodstarter.io/assets/images/mintale2.png" alt="step 2" />
						<h5>Step 2</h5>
						<p>Find and contribute to your favorite project</p>
					</div>
					<div className="col-12 col-md-4 text-center">
						<img src="https://marketplace.foodstarter.io/assets/images/success.png" alt="step 3" />
						<h5>Step 3</h5>
						<p>Sit back and relaxed, while waiting for your sales of produce returns</p>
					</div>
				</div>
			</div>
		</div>
	</div>
);

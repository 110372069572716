import { createCheckoutWithCardElement } from "@paperxyz/js-client-sdk";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loading from "./Loading";

export const CheckoutWithCard = ({
	sdkClientSecret,
	options = {},
	// options = {
	// 	...DEFAULT_BRAND_OPTIONS,
	// },
	onPaymentSuccess,
	onReview,
	onError,
	experimentalUseAltDomain,
	locale,
}) => {
	const appName = "Orientalist";
	const [isCardDetailIframeLoading, setIsCardDetailIframeLoading] = useState(true);
	const onCardDetailLoad = useCallback(() => {
		setIsCardDetailIframeLoading(false);
	}, []);

	const CheckoutWithCardIframeContainerRef = useRef(null);

	const [modalUrl, setModalUrl] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const closeModal = () => {
		setIsOpen(false);
	};

	// Handle message events from the popup. Pass along the message to the iframe as well
	useEffect(() => {
		if (!CheckoutWithCardIframeContainerRef.current) {
			return;
		}
		createCheckoutWithCardElement({
			onCloseKycModal() {
				console.log("called close modal");
				closeModal();
			},
			onOpenKycModal({ iframeLink }) {
				setModalUrl(iframeLink);
				setIsOpen(true);
			},
			sdkClientSecret,
			appName,
			elementOrId: CheckoutWithCardIframeContainerRef.current,
			locale,
			onError,
			onLoad: onCardDetailLoad,
			onPaymentSuccess,
			onReview,
			options,
			useAltDomain: experimentalUseAltDomain,
		});
	}, [CheckoutWithCardIframeContainerRef.current]);

	return (
		<>
			{isCardDetailIframeLoading && (
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
					<Loading />
				</div>
			)}
			<div
				className="relative h-full w-full"
				ref={CheckoutWithCardIframeContainerRef}
				style={{ opacity: isCardDetailIframeLoading ? 0 : 1 }}
			></div>

			<Modal show={isOpen} onHide={closeModal}>
				{modalUrl && (
					<iframe
						id="review-card-payment-iframe"
						src={modalUrl}
						className="h-[700px] max-h-full w-96 max-w-full"
						allow="camera"
					/>
				)}
			</Modal>
		</>
	);
};

import BuyButton from "../BuyButton";

const AwardBlock = () => (
	<div id="awards-block">
		<div className="container-fluid gx-0">
			<div className="row gx-0 align-items-center">
				<div className="col-12 col-md-5">
					<div className="brand-video">
						<video controls autoPlay muted loop playsInline width="100%">
							<source src="images/branding.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
				<div className="col-12 col-md-7">
					<div className="content">
						<div className="mobile-background-block">
							<h1>
								<span className="block">
									<span className="thin">MetaCafe NFT</span>
								</span>
								<span className="block">Metafarms + Philippines Coffee</span>{" "}
							</h1>
							<h3>Companies: Metafarms PH + Moonman Coffee</h3>
						</div>
						<div className="mobile-regular-block">
							<h3 className="highlighted" style={{ marginBottom: "25px" }}>
								Price 0.25 ETH
							</h3>
							<h3>Perks (Worth 0.5 ETH)</h3>
							<p>☕ 12 Bottles of Cold Brew</p>
							<p>☕ 1 Coffee Barista Sensory</p>
							<p>☕ Discount to franchise coffee vending machine</p>
							<BuyButton type="metacafe" cost="0.25 ETH" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default AwardBlock;

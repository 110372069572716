import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, polygonMumbai } from "wagmi/chains";
import App from "./App";
import DrawerProvider from "./providers/DrawerProvider";
import BlockchainProvider from "src/providers/BlockchainProvider";

const chains = [mainnet, polygonMumbai];
const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECTID;

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
	autoConnect: true,
	connectors: w3mConnectors({ projectId, chains }),
	publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const web3ModalConfig = {
	projectId: process.env.REACT_APP_WALLETCONNECT_PROJECTID,
	theme: "dark",
	accentColor: "default",
	ethereum: {
		appName: "web3Modal",
		autoConnect: true,
	},
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Router>
			<WagmiConfig config={wagmiConfig}>
				<BlockchainProvider>
					<DrawerProvider>
						<App />
					</DrawerProvider>
				</BlockchainProvider>
			</WagmiConfig>
			<Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
		</Router>
	</React.StrictMode>
);

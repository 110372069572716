import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

export default ({ onClose, show, children }) => {
	const handleClose = () => {
		onClose && onClose();
	};

	return (
		<>
			<Offcanvas placement="end" show={show} onHide={handleClose}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>&nbsp;</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>{children}</Offcanvas.Body>
			</Offcanvas>
		</>
	);
};

export const tokenName = nftType => {
	switch (nftType) {
		case "metacafe":
			return "MetaCafe NFT";
		case "porkie":
			return "Porkie Pig Farm NFT";
		case "foodtruck":
			return "Designer Foodstarter Foodtruck NFTs";
		default:
			return "Adlai Farm NFT";
	}
};

export const tokenSymbol = nftType => {
	switch (nftType) {
		case "metacafe":
			return "METACAFE";
		case "porkie":
			return "PORKIE";
		case "foodtruck":
			return "FSTRUCK";
		default:
			return "ADLAI";
	}
};

export const tokenImage = nftType => {
	switch (nftType) {
		case "metacafe":
			return "https://gateway.pinata.cloud/ipfs/QmTRbtVjPi5TwCq2Gfy3VLvxPguMVRHD9QoV2KpGnoBqv7";
		case "porkie":
			return "PORKIE";
		default:
			return "ADLAI";
	}
};

export const tokenAddress = nftType => {
	switch (nftType) {
		case "metacafe":
			return process.env.REACT_APP_METACAFE_DROPERC721_ADDRESS;
		case "porkie":
			return process.env.REACT_APP_PORKIE_DROPERC721_ADDRESS;
		case "adlai":
			return process.env.REACT_APP_ADLAI_DROPERC721_ADDRESS;
		case "foodtruck":
			return process.env.REACT_APP_FOODTRUCK_DROPERC721_ADDRESS;
	}

	return process.env.REACT_APP_PUBLIC_DROPERC721_ADDRESS;
};

import { useContext, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { DrawerContext } from "./providers/DrawerProvider";
import Drawer from "./componets/Drawer";
import routes from "src/routes";
import BuyBlock from "./componets/BuyBlock";
import "./App.css";

function App() {
	let element = useRoutes(routes);

	const { open, drawerFor, closeDrawer } = useContext(DrawerContext);

	const handleClose = () => {
		closeDrawer();
	};

	return (
		<div className="App">
			{element}
			<Drawer show={open} onClose={handleClose}>
				<BuyBlock nftType={drawerFor} />
			</Drawer>
		</div>
	);
}

export default App;
